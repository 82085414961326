/*!

=========================================================
* BLK Design System PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbar.js";

export default function Programming() {
  const wrapper = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    wrapper.current.scrollTop = 0;
    document.body.classList.add("programming-page");
    return function cleanup() {
      document.body.classList.remove("programming-page");
    };
  }, []);

  return (
    <>
      <ColorNavbar />
      <div className="wrapper" ref={wrapper}>
        <div className="squares square1" />
        <div className="squares square2" />
        <div className="squares square3" />
        <div className="squares square4" />
        <div className="squares square5" />
        <div className="squares square6" />
        <div className="features-3">
          <Container fluid>
            <Row>
              <Col className="mr-auto ml-auto" md="6">
                <h1 className="title">Programming Experience</h1>
                <h4 className="description">
                  Over the years I have diversified my programming
                  skills in various languages and libraries.
                </h4>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto" md="5">
                <div className="info info-horizontal">
                  <div className="icon icon-success">
                    <img
                      alt="..."
                      className="bg-blob"
                      src={
                        require("assets/img/feature-blob/success.png").default
                      }
                    />
                    <i className="tim-icons icon-controller" />
                  </div>
                  <div className="description">
                    <h3 className="info-title">C++ (+ Unreal Engine syntax)</h3>
                    <p>
                      C++ is a complex lower level programming language.
                      The beginnings of my C++ journey started with my 
                      passion (turned career) in Game Development.
                    </p>
                  </div>
                </div>
              </Col>
              <Col className="mr-auto" md="5">
                <div className="info info-horizontal">
                  <div className="icon icon-warning">
                    <img
                      alt="..."
                      className="bg-blob"
                      src={
                        require("assets/img/feature-blob/warning.png").default
                      }
                    />
                    <i className="tim-icons icon-heart-2" />
                  </div>
                  <div className="description">
                    <h3 className="info-title">C#/ASP.NET (+ Razor syntax)</h3>
                    <p>
                      During my career in Game Development, I began
                      exploring other programming languages. I was
                      drawn to C# for it's similarities to C++ and
                      it's ease of Windows UI creation.
                    </p>
                    <Button
                      className="btn-link"
                      color="info"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Read more <i className="tim-icons icon-minimal-right" />
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto" md="5">
                <div className="info info-horizontal">
                  <div className="icon icon-danger">
                    <img
                      alt="..."
                      className="bg-blob"
                      src={
                        require("assets/img/feature-blob/danger.png").default
                      }
                    />
                    <i className="tim-icons icon-chart-bar-32" />
                  </div>
                  <div className="description">
                    <h3 className="info-title">For Bootstrap Lovers</h3>
                    <p>
                      The moment you use Black Kit, you know you’ve never felt
                      anything like it. With a single use, this powerfull UI Kit
                      lets you do more than ever before.
                    </p>
                    <Button
                      className="btn-link"
                      color="info"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Explore now <i className="tim-icons icon-minimal-right" />
                    </Button>
                  </div>
                </div>
              </Col>
              <Col className="mr-auto" md="5">
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <img
                      alt="..."
                      className="bg-blob"
                      src={require("assets/img/feature-blob/info.png").default}
                    />
                    <i className="tim-icons icon-paper" />
                  </div>
                  <div className="description">
                    <h3 className="info-title">
                      Documentation{" "}
                      <Badge color="info" pill>
                        v2.0
                      </Badge>
                    </h3>
                    <p>
                      The moment you use Black Kit, you know you’ve never felt
                      anything like it. With a single use, this powerfull UI Kit
                      lets you do more than ever before.
                    </p>
                    <Button
                      className="btn-link"
                      color="info"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Explore now <i className="tim-icons icon-minimal-right" />
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section">
          <Container>
            <Col md="12">
              <h2 className="title text-center">Related Stories</h2>
              <br />
              <div className="blogs-1">
                <Row>
                  <Col className="ml-auto mr-auto" md="12">
                    <Card className="card-blog card-plain blog-horizontal">
                      <Row>
                        <Col lg="4">
                          <div className="card-image">
                            <a
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                alt="..."
                                className="img rounded"
                                src={
                                  require("assets/img/trae-gould.jpg").default
                                }
                              />
                            </a>
                          </div>
                        </Col>
                        <Col lg="8">
                          <CardBody>
                            <CardTitle tag="h3">
                              <a
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                MateLabs mixes machine learning with IFTTT
                              </a>
                            </CardTitle>
                            <p className="card-description">
                              If you’ve ever wanted to train a machine learning
                              model and integrate it with IFTTT, you now can
                              with a new offering from MateLabs. MateVerse, a
                              platform where novices can spin out machine...If
                              you’ve ever wanted to train a machine learning
                              model and integrate it with IFTTT, you now can
                              with a new offering from MateLabs. MateVerse, a
                              platform where novices can spin out machine...{" "}
                              <a
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Read More
                              </a>
                            </p>
                            <div className="author">
                              <img
                                alt="..."
                                className="avatar img-raised"
                                src={require("assets/img/james.jpg").default}
                              />
                              <div className="text">
                                <span className="name">Tom Hanks</span>
                                <div className="meta">Drawn on 23 Jan</div>
                              </div>
                            </div>
                          </CardBody>
                        </Col>
                      </Row>
                    </Card>
                    <Card className="card-blog card-plain blog-horizontal">
                      <Row>
                        <Col lg="4">
                          <div className="card-image">
                            <a
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                alt="..."
                                className="img rounded"
                                src={
                                  require("assets/img/mark-harrison.jpg")
                                    .default
                                }
                              />
                            </a>
                          </div>
                        </Col>
                        <Col lg="8">
                          <CardBody>
                            <CardTitle tag="h3">
                              <a
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                US venture investment ticks up in Q2 2017
                              </a>
                            </CardTitle>
                            <p className="card-description">
                              Venture investment in U.S. startups rose
                              sequentially in the second quarter of 2017,
                              boosted by large, late-stage financings and a few
                              outsized early-stage rounds in tech and
                              healthcare..enture investment in U.S. startups
                              rose sequentially in the second quarter of 2017,
                              boosted by large, late-stage financings and a few
                              outsized early-stage rounds in tech and
                              healthcare..{" "}
                              <a
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Read More
                              </a>
                            </p>
                            <div className="author">
                              <img
                                alt="..."
                                className="avatar img-raised"
                                src={require("assets/img/michael.jpg").default}
                              />
                              <div className="text">
                                <span className="name">Tom Hanks</span>
                                <div className="meta">Drawn on 23 Jan</div>
                              </div>
                            </div>
                          </CardBody>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Container>
        </div>
      </div>
    </>
  );
}
