/*!

=========================================================
* BLK Design System PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";

export default function Socials() {
  return (
    <>
      <div className="title text-center">
        <h3>Socials</h3>
      </div>
      <div className="social-line social-line-big-icons">
        <Container>
          <Row>
            <Col md="3">
              <Button
                className="btn-icon btn-simple btn-footer"
                color="github"
                href="https://github.com/jaydenmaalouf"
              >
                <i className="fab fa-github" />
              </Button>
            </Col>
            <Col md="3">
              <Button
                className="btn-icon btn-simple btn-footer"
                color="linkedin"
                href="https://linkedin.com/in/jaydenmaalouf"
              >
                <i className="fab fa-linkedin" />
              </Button>
            </Col>
            <Col md="3">
              <Button
                className="btn-icon btn-simple btn-footer"
                color="google"
                href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#106;&#97;&#121;&#100;&#101;&#110;&#46;&#109;&#46;&#109;&#97;&#97;&#108;&#111;&#117;&#102;&#64;&#103;&#109;&#97;&#105;&#108;&#46;&#99;&#111;&#109;"
              >
                <i className="fab fa-google" />
              </Button>
            </Col>
            <Col md="3">
              <Button
                className="btn-icon btn-simple btn-footer"
                color="twitter"
                href="https://twitter.com/jaydenmaalouf"
              >
                <i className="fab fa-twitter" />
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
